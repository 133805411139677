<template>
    <Layout>
        <div class="w-100">
            <div class="row justify-content-center">
                <div class="col-10 col-sm-7 col-md-5 col-lg-8 col-xl-7 col-xxl-6 login-wrapper py-5">
                    <div class="mx-auto login-w-limit">
                        <div class="logo-wrapper text-center mb-2">
                        </div>
                        <h4 class="text-center by-3">
                            {{ $t('dormitory_application_form') }}
                        </h4>
                        <div class="application-info d-flex flex-column mb-5">
                            <span class="text-center font-weight-bold my-3">{{ $t('application_conditions') }}</span>
                            <span class="mb-1">- {{ $t('student_bea_bau') }}</span>
                            <span class="mb-1">- {{ $t('student_unihall_info', ["2023-2024"]) }}</span>
                            <span class="mb-1">- {{ $t('student_unihall_info1') }}</span>
                            <span>- {{ $t('student_unihall_info2') }}</span>
                        </div>
                        <div class="fadeIn">
                            <ValidationObserver ref="form">
                                <div class="mx-auto">
                                    <ValidationProvider name="national_id" rules="required|numeric" v-slot="{ errors }">
                                        <b-form-group :label="$t('national_id')">
                                            <b-form-input type="text" v-model="form.national_id"
                                                :state="errors[0] ? false : null" />
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                    <ValidationProvider name="birthdate" rules="required" v-slot="{ errors }">
                                        <b-form-group :label="$t('birthdate')">
                                            <select-date v-model="form.birthdate" :readonly="false"
                                                :validation-error="errors[0]" />
                                        </b-form-group>
                                    </ValidationProvider>
                                    <div class="d-flex justify-content-center mb-4">
                                        <b-button variant="primary" block @click="login">
                                            {{ $t('login').toUpper() }}
                                        </b-button>
                                    </div>
<!--                                    <div @click="openModal('payment')">-->
<!--                                        <ValidationProvider name="payment.status" rules="oneOf:1" v-slot="{ errors }">-->
<!--                                            <b-form-checkbox @change="payment.status = false" :unchecked-value="false"-->
<!--                                                :value="true" v-model="payment.status" :state="errors[0] ? false : null">-->
<!--                                                <span v-html="$t('online_payment_read_and_approve')"></span>-->
<!--                                            </b-form-checkbox>-->
<!--                                            <b-form-invalid-feedback v-if="errors[0]">{{ $t('statement4_error')-->
<!--                                            }}</b-form-invalid-feedback>-->
<!--                                        </ValidationProvider>-->
<!--                                    </div>-->
                                    <div @click="openModal('kvkk')">
                                        <ValidationProvider name="kvkk.status" rules="oneOf:1" v-slot="{ errors }">
                                            <b-form-checkbox class="mt-2" @change="kvkk.status = false"
                                                :unchecked-value="false" :value="true" v-model="kvkk.status"
                                                :state="errors[0] ? false : null">
                                                <span v-html="$t('kvkk_data_approve')"></span>
                                            </b-form-checkbox>
                                            <b-form-invalid-feedback v-if="errors[0]">{{ $t('statement3_error')
                                            }}</b-form-invalid-feedback>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
            <CommonModal ref="paymentModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('online_payment_agreement').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row">
                        <div class="col-12 mb-4" style="white-space: pre-line;">
                            <p v-html="$t('online_payment_text')"></p>
                        </div>
                    </div>
                    <b-button variant="primary" type="button" @click="approve('payment')">{{ $t('read_and_accept')
                    }}</b-button>
                </template>
            </CommonModal>
            <CommonModal ref="kvkkModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div> {{ $t('kvkk_modal_title').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row">
                        <div class="col-12 mb-4" style="white-space: pre-line;">
                            <p v-html="$t('kvkk_content_text')"></p>
                        </div>
                    </div>
                    <b-button variant="primary" type="button" @click="approve('kvkk')">{{ $t('read_and_accept')
                    }}</b-button>
                </template>
            </CommonModal>
            <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified" size="invisible"
                sitekey="6LelIscaAAAAAA0ODBaTFs_FUqS3WTgy-G0bP1pG" />
        </div>
    </Layout>
</template>
<script>
import { VueRecaptcha } from 'vue-recaptcha'

import { mapGetters } from 'vuex'

import UnihallService from '@/services/UnihallService'

export default {
    name: "UnihallLogin",
    components: {
        VueRecaptcha,
        Layout: () => import("../layout/Layout")
    },
    data() {
        return {
            form: {},
            generalStatus: false,
            payment: {
                show: false,
                status: false,
                read: false
            },
            kvkk: {
                show: false,
                status: false,
                read: false
            },
        }
    },
    computed: {
        ...mapGetters({
            token: 'unihall/getToken'
        })
    },
    methods: {
        async login() {
            const valid = await this.$refs['form'].validate()
            if (valid) {
                this.$refs.recaptcha.execute();
            }

        },
        async onCaptchaVerified(recaptchaToken) {
            if (!this.generalStatus) {
                this.$swal.fire({
                    icon: null,
                    html: this.$t('unihall_quota_full'),
                    confirmButtonText: this.$t('ok'),
                })
                return
            }

            this.form.g_recaptcha_token = recaptchaToken;

            const response = await UnihallService.loginOrRegister(this.form)
                .catch((e) => {
                    this.showErrors(e);
                }).finally(() => {
                    this.$refs.recaptcha.reset()
                })

            if (response.data.success && response.data.data.token) {
                this.$store.commit('unihall/setForm', response.data.data.form)
                this.$store.commit('unihall/setToken', response.data.data.token)
                this.$router.push('/unihall/application');
                return
            }
            this.$toast.error(this.$t('something_went_wrong'));
        },
        openModal(str, status = true) {
            if (status) {
                this.$refs[`${str}Modal`].$refs.commonModal.show()
            } else {
                this.$refs[`${str}Modal`].$refs.commonModal.hide()
            }

        },
        approve(str) {
            this.$data[str].status = true
            this.openModal(str, false)
        },
        async applicationStatus() {
            try {
                await UnihallService.checkDate()
                this.generalStatus = true
            } catch (e) {
                this.generalStatus = false
                this.$swal.fire({
                    icon: null,
                    html: this.$t('unihall_quota_full'),
                    confirmButtonText: this.$t('ok'),
                })
            }
        }
    },
    mounted() {
        if (this.token !== null) {
            this.$router.push('/unihall/application');
        }
        this.applicationStatus()
    }
}
</script>
<style lang="">

</style>
